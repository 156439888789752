import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private statusService = new BehaviorSubject<boolean>(false);
  userLoggedIn = this.statusService.asObservable();

  constructor() { }

  public set(key: string, value: string) {
    sessionStorage.setItem(key, value);
    this.statusService.next(true);
  }

  get(key: string): string {
    return sessionStorage.getItem(key);
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
    this.statusService.next(false);
  }

  clear() {
    sessionStorage.clear();
    this.statusService.next(false);
  }
}
