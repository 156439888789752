import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WalletComponent } from './wallet/wallet.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { EnquiryReplyComponent } from './enquiry-reply/enquiry-reply.component';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then(m => m.EmailModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'apps',
    loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'widget',
    loadChildren: () =>
      import('./widget/widget.module').then(m => m.WidgetModule),
      canActivate: [AuthenticationGuardService]
  },
  {
    path: 'ui',
    loadChildren: () => import('./ui/ui.module').then(m => m.UiModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'tables',
    loadChildren: () =>
      import('./tables/tables.module').then(m => m.TablesModule),
      canActivate: [AuthenticationGuardService]
  },
  {
    path: 'media',
    loadChildren: () => import('./media/media.module').then(m => m.MediaModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'charts',
    loadChildren: () =>
      import('./charts/charts.module').then(m => m.ChartsModule),
      canActivate: [AuthenticationGuardService]
  },
  {
    path: 'timeline',
    loadChildren: () =>
      import('./timeline/timeline.module').then(m => m.TimelineModule),
      canActivate: [AuthenticationGuardService]
  },
  {
    path: 'icons',
    loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: 'extra-pages',
    loadChildren: () =>
      import('./extra-pages/extra-pages.module').then(m => m.ExtraPagesModule),
      canActivate: [AuthenticationGuardService]
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: '',
    redirectTo: '/manager/file-maker',
    pathMatch: 'full',
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'cars',
    loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'carshome',
    loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'wallet',
    component: WalletComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'enquiries',
    component: EnquiryComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'enquiry-reply',
    component: EnquiryReplyComponent,
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path:'auctions',
    loadChildren: () => import('./auctions/auctions.module').then(m => m.AuctionsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
