import { Injectable } from '@angular/core';
import axios from 'axios';
import { ConfigManager } from '../config/configmanager';
import { SessionService } from '../authentication/session.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  url = ConfigManager.config.api.url;
  apiKey = ConfigManager.config.api.api_key;
  adminKey = ConfigManager.config.api.admin_key;
  axiosConfig = {};
  axiosAdminConfig={};
  constructor(private sessionService: SessionService) {
     this.axiosConfig  = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }
    }
    this.axiosAdminConfig  = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.adminKey,
      }      
    }
  }

  async getBalance(user_id?:string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      return axios.get(`${this.url}/payments/all_payment?user_id=${user_id}`, this.axiosAdminConfig);
    } catch(e){
        //  TODO: Toastr  
    }
  }

  async getgroup(group_number?:string,user_id?:string): Promise<void> {
    try {
      return axios.get(`${this.url}/payments/all_payment?group_number=${group_number} &user_id=${user_id}`, this.axiosAdminConfig);
    } catch(e){
        //  TODO: Toastr  
    }
  }

  async getCredits(user_id?: string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      let transaction_type = "CREDIT";
      let limit = 10;
      let page_no = 1;
      let url = `${this.url}/user_deposit/user_deposit?user_id=${user_id}&transaction_type=${transaction_type}&page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
        //  TODO: Toastr  
    }
  }

  async getDebits(user_id?: string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      let transaction_type = "DEBIT";
      let limit = 10;
      let page_no = 1;
      let url = `${this.url}/user_deposit/user_deposit?user_id=${user_id}&transaction_type=${transaction_type}&page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
        console.error('Error getting debits')
    }
  }

  async createGroupNo(user_id): Promise<void> {
      try {
        return axios.put(`${this.url}/user_wallet/close_group`, {
            "user_id": user_id,
          }, this.axiosConfig);
      } catch(e){
          console.error('Error in updating the group')
      }
    }

  async getGroupDetails(user_id):Promise<void>{
    try{
      let url = `${this.url}/user_wallet/all_groups?user_id=${user_id}`;
      return axios.get(url, this.axiosAdminConfig);
    }catch(error){
       //  TODO: Toastr  
    }
  }
}
