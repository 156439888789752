<nav #navbar class="navbar sticky-top">
    <div class="container-fluid">
        <div class="navbar-header">
            <a href="#" onClick="return false;" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#navbar-collapse" aria-expanded="false"></a>
            <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
            <a class="navbar-brand pt-0" routerLink="manager/home">
                <img src="assets/images/plusgroup-logo.png" alt="" style="height: 35px;"/>
                <!-- <span class="logo-name">PLUS GROUP CORPORATION</span> -->
            </a>
        </div>
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="pull-left collapse-menu-icon">
                <li>
                    <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
                        <i class="nav-hdr-btn ti-align-left"></i>
                    </button>
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
                <!-- Full Screen Button -->
                <li class="fullscreen">
                    <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
                        <i class="nav-hdr-btn ti-fullscreen"></i>
                    </button>
                </li>
                
                <!-- #END# Notifications-->
                <li class="dropdown user_profile">
                    <a href="#" onClick="return false;" data-toggle="dropdown" role="button"
                        class="nav-notification-icons">
                        <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
                    </a>
                    <ul class="dropdown-menu pullDown">
                        <li class="body">
                            <ul class="user_dw_menu">
                                <li>
                                    <a href="#" onClick="return false;">
                                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Profile
                                    </a>
                                </li>

                                <li>
                                    <a routerLink="/authentication/signin" onClick="return false;">
                                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- #END# Tasks -->
                <!-- <li class="pull-right">
                    <button mat-icon-button (click)="toggleRightSidebar()" class="nav-notification-icons">
                        <i class="nav-hdr-btn ti-layout-grid2" id="settingBtn"></i>
                    </button>
                </li> -->
            </ul>
        </div>
    </div>
</nav>