<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card">
                    <div class="header">
                        <h2>Enquiry Reply</h2>
                        <span>{{enquiry_message}}</span>
                    </div>
                    <div class="body">
                        <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <!-- <th scope="col">Enquiry ID.</th> -->
                                <th scope="col">User ID</th>
                                <th scope="col">Message</th>                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let enquiries of enquiry">
                                <!-- <td><a href="javascript:void(0)" [routerLink]="['/enquiry']" [queryParams]= "{enquiry_id: enquiries.enquiry_id}">{{enquiries.enquiry_id}}</a></td> -->
                                <td>{{enquiries.user_id}}</td>
                                <div class="input-group m-t-30 flex"> 
                                  <td>{{enquiries.message}}</td>
                                  <input type="text" class="form-control" aria-describedby="button-addon2">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary" type="button" id="button-addon2">Chat</button>
                                  </div>
                                </div>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>

     
    </div> 
</section>