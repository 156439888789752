<footer class="footer p20">
    <div class="container">      
      <div class="container-fluid">
        <div class="row clear-fix">
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="card p20">
                        <div class="header">
                            <h3><strong>About us</strong></h3>
                        </div>
                        <div class="body">
                            <p>Since 2006, Nexo Corporation has become an industry leader in conversion of vehicles from right to left hand drive. We help our clients purchasing the cars, Converting and shipping to their countries</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="card p20">
                        <div class="header">
                            <h3><strong>Useful Links</strong></h3>
                        </div>
                        <div class="body">
                            <ul class="lqd-custom-menu reset-ul font-size-14 lh-2">
                                <li class="mb-1"><a href="corporate.html" target="_blank">Corporate</a></li>
                                <li class="mb-1"><a href="about.html" target="_blank">About Us</a></li>
                                <li class="mb-1"><a href="conversion.html" target="_blank">Conversion</a></li>
                                <li class="mb-1"><a href="oneordershipping.html" target="_blank">One Order Shipping</a></li>
                            </ul>
                        </div>
                    </div>						
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="card p20">
                        <div class="header">
                            <h3><strong>Contact Us</strong></h3>
                        </div>
                        <div class="body">
                            <p>
                                Call: +81 78 4510059 <br>
                                Fax:  +81 78 3363359 <br>
                                Mail: info@nexo11.com <br>
                                Address: 6-16-11 Okamoto, Higashinada Ku, Kobe Shi, Hyogo Ken, Japan
                            </p>
                        </div>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </footer>