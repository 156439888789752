<section class="content wallet-main">
    <div class="container-fluid">
        <h4>Total Balance</h4>
        <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Currency</th>
                <th scope="col">Deposited</th>
                <th scope="col">Purchased</th>
                <th scope="col">Balanced</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="balance">
                <td>¥</td>
                <td>{{balance.total_credit}}</td>
                <td>{{balance.total_debit}}</td>
                <td>{{balance.balance}}</td>
              </tr>
            </tbody>
        </table>


        <div class="bd-example row m-t-100">
            <div class="container photos-container col-sm-4">
                <h5>Payment Plan</h5>
                <table class="table">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Overdue</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="overdue">
                        <td>CASH</td>
                        <td>{{overdue.total_due}}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="table">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Due Date</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let order of orders">
                        <td>{{order.due_date}}</td>
                        <td>{{order.invoice_number}}</td>
                        <td>{{order.total_amount}} ¥</td>
                    </tr>    
                    <!-- <td><a href="javascript:void(0)" routerLink="/orders/invoice">1322342349</a></td> -->
                    </tbody>
                </table>
            </div>
            <div class="container photos-container col-sm-4">
                <h5>Recent credits</h5>
                <table class="table">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Amount <span class="m-l-10">(¥)</span></th>
                        <th scope="col">Balance <span class="m-l-10">(¥)</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let credit of credits">
                        <td>{{credit.payment_date}}</td>
                        <td>{{credit.amount}}</td>
                        <td>{{credit.balance}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="container photos-container col-sm-4">
                <h5>Recent debits</h5>
                <table class="table">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Amount <span class="m-l-10">(¥)</span></th>
                        <th scope="col">Balance <span class="m-l-10">(¥)</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let debit of debits">
                        <td>{{debit.payment_date}}</td>
                        <td>{{debit.amount}}</td>
                        <td>{{debit.balance}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>