import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ApisService } from '../apis.service';
declare const $: any;

@Component({
  selector: 'app-enquiry-reply',
  templateUrl: './enquiry-reply.component.html',
  styleUrls: ['./enquiry-reply.component.css']
})
export class EnquiryReplyComponent implements OnInit, OnDestroy {

  enquiry_id: string;
  enquiry_message = "";
  enquiry = [];
  enquiries_Reply = { "enquiry_id": "", "message": "", "user_id": "" };
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private apisService: ApisService) { }

  @Input('small') small: boolean;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this.enquiry_id = params["enquiry_id"];
        this.loadEnquiry();
      }
    });
    // this.loadEnquiryReply(page_no, limit);
  }

  scrollLoad = true;
  pageNumber = 1;
  noData = false;
  async ngAfterContentInit(): Promise<void> {
    const limit = 20;
    const data = await this.loadEnquiryReply(1, limit);
    this.enquiry = [...this.enquiry, ...data];

    if (!this.small) {
      $(window).scroll(async () => {
        if (this.scrollLoad && !this.noData && ($(document).height() - $(window).height()) - $(window).scrollTop() <= 30) {
          this.scrollLoad = false;
          this.pageNumber += 1;
          const newData = await this.loadEnquiryReply(this.pageNumber, limit);
          if (!newData) {
            this.noData = true;
            this.scrollLoad = true;
            return;
          }
          this.enquiry = [...this.enquiry, ...newData || []];
          this.scrollLoad = true;
        }
      });
    }
  }

  async loadEnquiry() {
    this.spinner.show();
    try {
      let data = await this.apisService.getEnquiry(this.enquiry_id);
      this.spinner.hide();
      let enquiry = data["data"]["enquiry"];
      this.enquiry_message = enquiry !== null ? enquiry["user_message"] : "";
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the enquiries");
      }
    }
  }

  async loadEnquiryReply(page_no, limit) {
    this.spinner.show();
    try {
      let data = await this.apisService.getEnquiries(this.enquiry_id, page_no, limit);
      this.spinner.hide();
      return data["data"]["enquiry"];

    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the enquiries");
      }
    }
    this.spinner.hide();
    return [];
  }

  private formatEnquiryReply(enquiryReply: any) {
    this.enquiries_Reply = {
      enquiry_id: enquiryReply[0].enquiry_id,
      message: enquiryReply[0].message,
      user_id: enquiryReply[0].user_id,
    };
  }

  async createReply() {
    this.spinner.show();
    try {
      const data = await this.apisService.enquiryReply(this.enquiries_Reply.enquiry_id, this.enquiries_Reply.message, this.enquiries_Reply.user_id);
      this.spinner.hide();
      // return data["data"]["enquiry_Reply"]; 
      this.toastr.success("message updated successfully")
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while creating the enquiry");
      }
      this.spinner.hide();
      return [];
    }

  }

  @HostListener('unloaded')
  ngOnDestroy() {
    $(window).off("scroll");
  }

}
