import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigManager {
    static config:any;
    constructor(private http: HttpClient) {}
    load() {
        let environmentName = environment.production ? "prod" : "dev";
        const jsonFile = `assets/config/config-${environmentName}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response) => {
               ConfigManager.config=response;
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
