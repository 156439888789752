import { SessionService } from './../authentication/session.service';
import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ApisService } from '../apis.service';
declare const $: any;


@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit, OnDestroy {

  enquiries = [];
  messages = [];
  // enquiries = {};
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sessionservice: SessionService,
    private apisService: ApisService) { }

  @Input('small') small: boolean;
  ngOnInit(): void {
    this.messages = [];
    this.user_id = this.sessionservice.get('user_id');
    $('#viewMessage').on('hidden.bs.modal', (e) => {
      this.messages = [];
    });
  }

  scrollLoad = true;
  pageNumber = 1;
  noData = false;
  async ngAfterContentInit(): Promise<void> {
    const limit = 50;
    const data = await this.loadEnquiry(1, limit);
    this.enquiries = [...this.enquiries, ...data];

    if (!this.small) {
      $(window).scroll(async () => {
        if (this.scrollLoad && !this.noData && ($(document).height() - $(window).height()) - $(window).scrollTop() <= 30) {
          this.scrollLoad = false;
          this.pageNumber += 1;
          const newData = await this.loadEnquiry(this.pageNumber, limit);
          if (!newData) {
            this.noData = true;
            this.scrollLoad = true;
            return;
          }
          this.enquiries = [...this.enquiries, ...newData || []];
          this.scrollLoad = true;
        }
      });
    }
  }

  // private formatEnquiry(enquiryDetails: any) {
  //   this.enquiries = {
  //     enquiry_id: enquiryDetails[0].enquiry_id,
  //     user_id: enquiryDetails[0].user_id,
  //     car_id: enquiryDetails[0].car_id,
  //     message: enquiryDetails[0].message,

  //   };
  // }

  async loadEnquiry(page_no, limit) {
    this.spinner.show();
    try {
      const data = await this.apisService.getAllEnquiry(page_no, limit);
      this.spinner.hide();
      return data["data"]["enquiries"];
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the enquiry");
      }
      this.spinner.hide();
      return [];
    }

  }

  @HostListener('unloaded')
  ngOnDestroy() {
    $(window).off("scroll");
  }

  enquiry_id: any;
  userId: any;
  enquiryMessage: any;
  user_id: any;

  async updateEnquiry(enquiry_id, user_id) {
    this.enquiry_id = enquiry_id;
    this.userId = user_id;
    await this.getMessages();
  }

  async replyUserMessage() {
    try {
      await this.apisService.enquiryReply(this.user_id, this.enquiry_id, this.enquiryMessage);
      this.messages.push({
        created_date: new Date(),
        message: this.enquiryMessage,
        user_id: this.user_id
      })
      this.enquiryMessage = '';
    } catch (err) {
      this.toastr.error("Unable to send the message to user. Please try again.");
    }
  }

  async getMessages() {
    try {
      let messageDetails = await this.apisService.getEnquiry(this.enquiry_id);
      messageDetails.forEach((msgDet) => {
        this.messages.unshift(msgDet);
      });
    } catch (err) {
      this.toastr.error("Unable to load the messages from user. Please try again.");
    }
  }

  stock_no: any;
  acceptStock(stock_no) {
    this.stock_no = stock_no;
  }

  async stockAccept() {
    try {
      this.spinner.show();
      await this.apisService.stockAccept(this.stock_no);
      this.enquiries = [];
      const data = await this.loadEnquiry(1, 50);
      this.enquiries = [...this.enquiries, ...data];
      this.spinner.hide();
      this.toastr.success("sucessfully accepted the car.");
    } catch (err) {
      this.spinner.hide();
      this.toastr.error("Something went wrong while accepting the car.");
    }
  }
}
