<!-- <h4 class="m-t-30 m-l-30">Enquire Details about your car</h4> -->

<section class="content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="card">
                  <div class="header">
                      <h2>Enquiries</h2>
                  </div>
                  <div class="body">
                      <table class="table">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Enquiry ID</th>
                              <th scope="col">User ID</th>
                              <th scope="col">Stock No</th>
                              <th scope="col">Car Name</th>
                              <th scope="col">Car Type</th>
                              <th scope="col">Update</th>
                              <th scope="col">Name</th>
                              <th scope="col">Phone No</th>
                              <th scope="col">Country</th>
                              <th scope="col">Email</th>
                              <th scope="col">Message</th>                          
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let enquiry of enquiries">
                              <!-- <td><img style="width:80px"  [src]="order.image" routerLink="/carshome/carsDetails"></td> -->
                              <!-- <td><a href="javascript:void(0)" [routerLink]="['/enquiry-reply']" [queryParams]= "{enquiry_id: enquiry.enquiry_id}">{{enquiry.enquiry_id}}</a></td> -->
                              <td>{{enquiry.enquiry_id}}</td>
                              <td>{{enquiry.user_id}}</td>
                              <td><a href="javascript:void(0)" [routerLink]="['/manager/file-maker']" [queryParams]= "{stock_no: enquiry.stock_no}">{{enquiry.stock_no}}</a></td>
                              <td>{{enquiry.car_name}}</td>
                              <td>{{enquiry.car_type}}</td>
                              <td>
                                <button *ngIf="enquiry.car_type == 'stock'" class="btn btn-primary btn-sm acceptStock" data-toggle="modal" data-target="#acceptStock" (click)="acceptStock(enquiry.stock_no)">
                                  <mat-icon>done_all</mat-icon>
                                </button>                  
                              </td>
                              <!-- <td>{{enquiry.car_type == "stock" ? <button class="material-icons-two-tone" data-toggle="modal" data-target="#acceptStock" (click)="acceptStock(enquiry.stock_no)">accept</button> : "" }}</td> -->
                              <td>{{enquiry.user_id == Null ? enquiry.name:  enquiry.first_name }}</td>
                              <td>{{enquiry.phone_no}}</td>
                              <td>{{enquiry.country}}</td>
                              <td>{{enquiry.email}}</td>        
                              <td>
                                <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#viewMessage" (click)="updateEnquiry(enquiry.enquiry_id,enquiry.user_id)">Open</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<div class="modal fade" id="viewMessage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">View Message</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="body-chat">
        <div class="row p-0 m-0 w-100" *ngFor="let details of messages">
          <div class="col-10 p-0 mr-auto mt-3" *ngIf="user_id != details.user_id">
            <div class="user-message p-3 d-inline-block">
              <div>{{details.name != null ? details.name : details.first_name}}</div>
              {{details.message}}
            </div>
            <div class="chat-time">{{details.created_date | date:'yyyy-MM-dd hh:mm'}}</div>
          </div>
          <div class="col-10 p-0 ml-auto mt-3 text-right pr-2" *ngIf="user_id == details.user_id">
            <div class="admin-message p-3 text-right d-inline-block">
              <div>{{details.first_name}}</div>
              {{details.message}}
            </div>
            <div class="chat-time text-right">{{details.created_date | date:'yyyy-MM-dd hh:mm'}}</div>
          </div>
        </div>
      </div>
    
    <div class="input-group m-t-30 flex"> 
      <!-- <input type="text" class="form-control" aria-describedby="button-addon2"> -->
      <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" [(ngModel)]="enquiryMessage">    
      <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="replyUserMessage()">Send</button>
      </div>
    </div>
    </div>
  </div>
</div>

<div class="modal fade" id="acceptStock" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="acceptStockLabel" aria-hidden="true">
  <div class="modal-dialog"style = "width:350px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title m-l-20 m-t-20 m-b-20" id="acceptStockLabel">Accept Car</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="pl-3">
          Are you sure you want to accept the car ?
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="stockAccept()">Confirm</button>
      </div>
    </div>
  </div>
</div>


