import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.sass']
})
export class LeftMenuComponent implements OnInit {

  contentHeight = '0px';
  userName = '';

  constructor() { }

  ngOnInit(): void {
    let height = window.innerHeight;
    this.contentHeight = (height - 40) + 'px';
    this.userName = sessionStorage.getItem('userName');
  }

}
