<div class="menu-container p-0 p-3 position-relative" [ngStyle]="{'height': contentHeight}">
    <div>
        <img src="assets/images/usrbig.jpg" alt="user-profile" class="user-profile">
        <div class="profile-name mt-2">Welcome <br> {{userName}} !</div>
    </div>
    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/manager">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-dashboard"></i>
        </div>
        <div class="col ps-2 menu-text">
            PG-Cars
        </div>
    </div>
    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/manager/register-form">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-comments"></i>
        </div>
        <div class="col ps-2 menu-text">
            JDM-Enquiry
        </div>
    </div>

    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/auctions/car-bids">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-dashboard"></i>
        </div>
        <div class="col ps-2 menu-text">
            Auctions
        </div>
    </div>
    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/users/users">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-user"></i>
        </div>
        <div class="col ps-2 menu-text">
            Users
        </div>
    </div>
    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/orders/payments">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-money"></i>
        </div>
        <div class="col ps-2 menu-text">
            Payments
        </div>
    </div>
    <div class="row p-0 m-0 w-100 mt-4 menu-items d-flex align-items-center" routerLink="/enquiries">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon ti-comments"></i>
        </div>
        <div class="col ps-2 menu-text">
            Enquiries
        </div>
    </div>
    <div class="row p-0 m-0 w-100 logout-container menu-items d-flex align-items-center" routerLink="/authentication/signin">
        <div class="col-auto p-0 px-1">
            <i class="menu-icon icon-logout"></i>
        </div>
        <div class="col ps-2 menu-text">
            Logout
        </div>
    </div>
</div>