<app-page-loader></app-page-loader>

<div class="row p-0 m-0 w-100">
    <div class="col-auto p-0">
        <app-left-menu *ngIf="!_router.url.includes('/authentication')"></app-left-menu>
    </div>
    <div class="col p-0 pl-2">
        <router-outlet></router-outlet>
    </div>
</div>
