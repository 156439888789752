import { Injectable } from '@angular/core';
import axios from 'axios';
import { ConfigManager } from '../config/configmanager';
import { SessionService } from '../authentication/session.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  
  url = ConfigManager.config.api.url;
  apiKey = ConfigManager.config.api.api_key;
  adminKey = ConfigManager.config.api.admin_key;
  axiosConfig = {};
  axiosAdminConfig = {};

  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
     this.axiosConfig  = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }      
    }
    this.axiosAdminConfig  = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.adminKey,
      }      
    }
  }

  async getOrders(page_no=1,limit=10, status?:Number, user_id?:string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      let url = `${this.url}/manager/non_invoice_orders?user_id=${user_id}&page_no=${page_no}&limit=${limit}`;
      if(status !== undefined && status !== null){
        url += `&status=${status}`;
      }
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getInvoices(page_no=1,limit=10, user_id?:string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      let url = `${this.url}/manager/orders?user_id=${user_id}&page_no=${page_no}&limit=${limit}`;
      
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getAllOrders(page_no=1,limit=10, status): Promise<void> {
    try {
      let url = `${this.url}/orders/all_orders?page_no=${page_no}&limit=${limit}`;
      if(status){
        url += `&status=${status}`;
      }
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getOverdue(user_id?: string): Promise<void> {
    try {
      user_id = user_id || this.sessionService.get("user_id");
      let url = `${this.url}/orders/overdue?user_id=${user_id}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }
  
  async getOrder(order_id): Promise<void> {
    try {
      //let user_id = this.sessionService.get("user_id");
      //let url = `${this.url}/orders/order?order_id=${order_id}&user_id=${user_id}`;
      let url = `${this.url}/orders/order?order_id=${order_id}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async createOrder(car_id, price): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/orders/order`;
      let payload = {user_id: user_id, car_id: car_id, price:price, port:"", address:""};
      return axios.post(url, payload, this.axiosConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }
  async createOrderAdmin(user_id, name): Promise<void> {
    try {
      let url = `${this.url}/orders/create_order`;
      let payload = {user_id: user_id, name:name};
      return await axios.post(url, payload, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getShipments(order_id): Promise<void> {
    try {
      //let user_id = this.sessionService.get("user_id");
      //let url = `${this.url}/shipments/shipment?order_id=${order_id}&user_id=${user_id}`;
      let url = `${this.url}/shipments/shipment?order_id=${order_id}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getPayments(page_no=1,limit=10, transaction_type, user_id?:string): Promise<any> {
    try {
      let adminAuthConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('token'),
        }
      }
      let url = `${this.url}/admin/payments?page_no=${page_no}&limit=${limit}`;
      if(transaction_type){
        url += `&transaction_type=${transaction_type}`;
      }
      if(user_id){
        url += `&user_id=${user_id}`;
      }
      return axios.get(url, adminAuthConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }
  async getTotalActiveShipments(): Promise<any> {
    try {
      let url = `${this.url}/shipments/total_active_shippment`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async getTotalActiveOrders(): Promise<any> {
    try {
      let url = `${this.url}//orders/total_active_orders`;
      return axios.get(url, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async user_update_payment(
    user_id,
    transaction_type,
    payment_method,
    amount,
    additional_notes,
  ): Promise<void> {
    try {
      return axios.post(`${this.url}/payments/payment`, {
          "user_id": user_id,
          "transaction_type":transaction_type,
          "payment_method": payment_method,
          "amount": amount,
          "additional_notes":additional_notes
        }, this.axiosConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async createShipment(shipment): Promise<any> {
    try {
      var form_data = new FormData();
      for ( var key in shipment) {
          form_data.append(key, shipment[key]);
      }
      let config = this.axiosAdminConfig;
      config["headers"]["Content-Type"] = "multipart/form-data";
      return axios.post(`${this.url}/shipments/shipment`, form_data, config);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async order_paid_update(
    order_id,
    paid,
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/orders/order`, {
          "order_id": order_id,
          "paid": paid
        }, this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }

  async order_Delete(
    order_id,
  ): Promise<void> {
    try {
      return axios.delete(`${this.url}/orders/order?order_id=${order_id}`,this.axiosAdminConfig);
    } catch(e){
      this.toastr.error("Something went wrong");
    }
  }
}
