import { Injectable } from '@angular/core';
import axios from 'axios';
import { ToastrService } from 'ngx-toastr';
import { ConfigManager } from '../app/config/configmanager';
import { SessionService } from './authentication/session.service';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  url = ConfigManager.config.api.url;
  apiKey = ConfigManager.config.api.api_key;
  adminKey = ConfigManager.config.api.admin_key;
  axiosConfig = {};
  axiosAdminConfig = {};

  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }
    };
    this.axiosAdminConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.adminKey,
      }
    };

  }

  async signup({
    email,
    password,
    firstName,
    lastName,
    phoneNo,
    role
  }): Promise<void> {
    try {
      return axios.post(`${this.url}/users/user`, {
        "first_name": firstName,
        "last_name": lastName,
        "email_id": email,
        "phone_no": phoneNo,
        "password": password,
        "role": role
      }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Error Signing Up', 'Error')
    }
  }

  async signin({
    email,
    password,
  }): Promise<void> {
    try {
      return axios.post(`${this.url}/admin/login`, {
        "email_id": email,
        "password": password,
      }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Error Signing In', 'Error')
    }
  }

  async changePassword(
    email,
    password,
    new_password
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/users/change_password`, {
        "email_id": email,
        "password": password,
        "new_password": new_password,
      }, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getUser(user_id): Promise<void> {
    try {
      return axios.get(`${this.url}/users/user?user_id=${user_id}`, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async updateUser(
    user_id,
    first_name,
    last_name,
    address,
    country,
    phone_no,
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/users/user`, {
        "user_id": user_id,
        "first_name": first_name,
        "last_name": last_name,
        "address": address,
        "country": country,
        "nik_name": first_name,
        "phone_no": phone_no,
        "pincode": "0000"
      }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getAllUsers(page_no = 1, limit = 10, status): Promise<any> {
    try {
      let url = `${this.url}/users/all_users?page_no=${page_no}&limit=${limit}`;
      if (status) {
        url += `&transaction_type=${status}`;
      }
      return axios.get(url, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }
  async getTotalActiveUsers(): Promise<any> {
    try {
      let url = `${this.url}/users/total_active_users`;
      return axios.get(url, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async admin_user_update(
    user_id,
    status,
    can_buy,
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/users/user_status`, {
        "user_id": user_id,
        "status": status,
        "can_buy": can_buy
      }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getAllEnquiry(page_no = 1, limit = 10): Promise<void> {
    try {
      let url = `${this.url}/enquiry/all_enquiries?page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async enquiryReply(
    user_id,
    enquiry_id,
    message,
  ): Promise<void> {
    try {
      let url = `${this.url}/enquiriy/admin_reply`;
      return axios.post(url, {
        "enquiry_id": enquiry_id,
        "message": message,
        "user_id": user_id
      }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getEnquiries(enquiry_id, page_no = 1, limit = 10): Promise<void> {
    try {
      let url = `${this.url}/enquiry/enquiry_replies?enquiry_id=${enquiry_id}&page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getEnquiry(enquiry_id, page_no = 1, limit = 30): Promise<any> {
    try {
      let url = `${this.url}/enquiry/all_messages?enquiry_id=${enquiry_id}&page_no=${page_no}&limit=${limit}`;
      let get_message = await axios.get(url, this.axiosAdminConfig);
      return get_message["data"]["messages"];
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async non_invoice_cars(user_id): Promise<any> {
    try {
      let url = `${this.url}/manager/get_non_invoice?user_id=${user_id}`;
      let name_result = await axios.get(url, this.axiosAdminConfig);
      return name_result["data"]["cars"];
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
      return null;
    }
  }

  async gerenate_invoice_in_user(car_ids): Promise<any> {
    try {
      let url = `${this.url}/manager/generate_invoice_for_non_invoice`;
      let result = await axios.post(url, { "car_ids": car_ids }, this.axiosAdminConfig);
      return result["data"];
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getInvoiceDetailes(invoiceNo): Promise<void> {
    try {
      return axios.get(`${this.url}/manager/invoice?invoice_number=${invoiceNo}`, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async stockAccept(stock_no): Promise<void> {
    try {
      return axios.put(`${this.url}/manager/accept_stock?stock_no=${stock_no}`, "", this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async changeInvoiceStatus(invoice_number, allow_invoice): Promise<void> {
    try {
      return axios.put(`${this.url}/manager/allow_invoice?invoice_number=${invoice_number}&allow_invoice=${allow_invoice}`, "", this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async updatePaymentTerms(invoice_number, payment_term): Promise<void> {
    try {
      return axios.put(`${this.url}/manager/payment_terms?invoice_number=${invoice_number}&payment_term=${payment_term}`, "", this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }
  
  async updatePaymentDetails(user_id, payment_details): Promise<void> {
    try {
      return axios.post(`${this.url}/users/payments_details`, { "user_id": user_id, "payments_details": payment_details }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getPaymentDetails(user_id): Promise<void> {
    try {
      let response = await axios.get(`${this.url}/manager/payments_list?user_id=${user_id}`, this.axiosAdminConfig);
      return response['data']['payments'];
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getAcceptBidsDetail(user_id): Promise<void> {
    try {
      return axios.get(`${this.url}/manager/get_accepted_bids_details?user_id=${user_id}`, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async uploadPaymentsDocuments(file, file_types, file_name, index_value, user_id) {
    try {
      return axios.post(`${this.url}/manager/upload_payments_documents?file_type=${file_types}&file_name=${file_name}&user_id=${user_id}&index_value=${index_value}`, file, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  async getFilemakerImport(arrayList): Promise<void> {
    try {
      return axios.post(`${this.url}/manager/filemaker_import`, { "arrayList": arrayList }, this.axiosAdminConfig);
    } catch (e) {
      this.toastr.error('Something went wrong', 'Error');
    }
  }
}
