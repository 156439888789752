import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {

    constructor(
        private sessionService: SessionService,
        private router: Router
    ){ }

    canActivate():boolean{
        if(!this.sessionService.get("user_id")){
            this.router.navigate(['/authentication/signin']);
            return false;
        }
        return true;
    }
}